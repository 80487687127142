import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import './HowItWorks.scss';

function HowItWorks() {
  return (
    <>
      <Header />
      <div className='page-content-container'>
        <div className="page-content">

          <div className='row'>
            <h1 className="page-title">How It Works</h1>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">Unlocking Your Potential: The Process Explained</h4>
              <p className='section-text'>
              I don’t believe in a one-size-fits-all solution to spiritual healing.  With that in mind, we will embark on a healing process that is specifically tailored to you. Your transformative journey begins with a three-session package tailored to foster healing and remove negativity from your life. These immersive experiences are thoughtfully crafted. Each session serves as a gateway to dive deep into your inner energy, unlocking your inherent potential and restoring a harmonious sense of balance and peace. By your engagement with the process, you will be empowered to let go of negativity that may have held you back. This will allow for personal growth, renewal, and a revitalized outlook on life.
              </p>
              <p className='section-text'>
              My unique path and experiences have led me to focus on aligning the Mind, Heart, and Spirit. I’ve learned that this alignment is crucial for attaining a heightened sense of well-being and inner peace. Together we can uncover and amplify this powerful energy in you. Whether you’ve been to the depths of despair or just want to learn a new, kinder, softer way to treat yourself, I’m here for you. I have no judgement! My aim is to help you foster connections and navigate your spiritual journey.  I want you to create a spiritual team of your understanding. Each session is prepared with care, through prayer with the ultimate goal of empowering you. Together, we can uncover the guidance you need for your future and your personal growth.
              </p>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">What’s Included</h4>
              <ul className='page-list-container'>
                <li>Three(3) one-on-one sessions with me.</li>
                <li>Sessions are over the phone and usually last an hour but can also be longer.</li>
                <li>Follow-up sessions are optional* (additional cost)</li>
              </ul>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">Cost: $320</h4>
              <p className='section-text'>
              Guided by my intuition and your unique energy, each session unfolds organically, for a more fluid, adaptable approach. As we navigate the spiritual realm together, we allow your personal path to healing and transformation to naturally reveal itself. We will be able to tune in and unlock the most effective journey towards your spiritual growth and healing.
              </p>
              <h4 className="page-sub-title">*Additional Sessions: $125</h4>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <p className='section-text'>
                <em>
                  NOTE: I offer a sliding scale for my spiritual coaching sessions to ensure they are accessible to everyone. Please reach out to discuss a rate that works for your financial situation.
                  <br/>
                  <br/>
                  I firmly believe in creating the right energy dynamics. If at any point during our initial journey together it doesn’t feel right, or I feel I cannot provide the help you need, I will refund any remaining sessions. Your trust and well-being are paramount to me.
                </em>
              </p>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">Services: (Plus other services available upon request)</h4>
              <ul className='page-list-container'>
                <li>Surrender to the Divine</li>
                <li>Release stored pain and fear</li>
                <li>Release anxious thoughts</li>
                <li>Change negative thinking</li>
                <li>Create a spiritual team to call on through prayer for guidance</li>
                <li>Learn how to meditate</li>
                <li>Become non-judgmental towards yourself and others</li>
                <li>Learn the difference between your ego and intuition</li>
                <li>Detach from all negative energy and people who do not support you</li>
                <li>Learn to love yourself fully, unconditionally</li>
                <li>Vibrate at a high level and attract people at that level</li>
                <li>Forgiveness</li>
                <li>Courage and confidence practices</li>
                <li>Gratitude practices</li>
                <li>Detach from others and allow them to walk their own path</li>
              </ul>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">Payments</h4>
              <div className='section-text'>
                Payment must be made in order to schedule an appointment.
              </div>

              <div className="payments-wrapper">
                <div id="paypal-container-DUDGDQ6QHVLEE"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default HowItWorks;